import React, { useContext } from 'react';
import axios from 'axios';
import { message } from 'antd';
import './CreditPage.css';
import Banner from './Banner';
import { UserContext } from '../Hooks/UserContext';

const CreditPage = () => {

  const apiUrl = process.env.REACT_APP_API_BASE_URL;
  const { token, user } = useContext(UserContext); 
  const handlePurchase = async (amount) => {
    try {

      const response = await axios.post(
        `${apiUrl}/users/${user.id}/purchase`,
        { amount },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      message.success(`Successfully purchased ${amount} credits!`);
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } catch (error) {
      message.error('Purchase failed. Please try again.');
    }
  };

  return (
    <div>
      <Banner />
      <div className="price-table-wrapper">
        <h1>Buy Credits</h1>
        <div className="pricing-table">
          <h2 className="pricing-table__header">- BASIC -</h2>
          <h3 className="pricing-table__price">€50</h3>
          <a
            className="pricing-table__button"
            onClick={() => handlePurchase(100)}
          >
            Buy Now!
          </a>
          <ul className="pricing-table__list">
            <li>100 Credits</li>
            <li>4 Jobs</li>
          </ul>
        </div>

        <div className="pricing-table featured-table">
          <h2 className="pricing-table__header">- BUSINESS -</h2>
          <h3 className="pricing-table__price">€80</h3>
          <a
            className="pricing-table__button"
            onClick={() => handlePurchase(250)}
          >
            Buy Now!
          </a>
          <ul className="pricing-table__list">
            <li>250 Credits</li>
            <li>10 Jobs</li>
            <li>Article Supplement Format</li>
          </ul>
        </div>

        <div className="pricing-table">
          <h2 className="pricing-table__header">- PREMIUM -</h2>
          <h3 className="pricing-table__price">€130</h3>
          <a
            className="pricing-table__button"
            onClick={() => handlePurchase(500)}
          >
            Buy Now!
          </a>
          <ul className="pricing-table__list">
            <li>500 Credits</li>
            <li>20 Jobs</li>
            <li>Article Supplement Format</li>
            <li>24 hour support</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default CreditPage;

