import React, { useContext } from 'react'
import genfoquestlogo from "../assets/img/genfoquestlogo.svg"
import "./Banner.css"
import { UserContext } from '../Hooks/UserContext';
const Banner = () => {
  const { user } = useContext(UserContext); 
  return (
    <div className='banner'>
    <div className='logo-container'>
  <img src={genfoquestlogo} alt="genfoquestlogo" className='logogenfoquest'/> 
  </div>
  <div>
  <h1 className='bannertitle'> Welcome to Single Cell Quest</h1>
  <h2 className='bannersubtext'> We Simplify Bioinformatics For You</h2>
  {user && <h3 className='bannercredit'>You have {user.credit} credits.</h3>}
  </div>
    </div>
  )
}

export default Banner
