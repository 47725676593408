import React, { useEffect, useState, useContext } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { makeStyles } from "@mui/styles";
import Banner from "./Banner";
import "./RunHistory.css";
import { IconButton } from "@mui/material";
import {
  TrendingUpOutlined as TrendingUpOutlinedIcon,
  FeedbackOutlined as FeedbackOutlinedIcon,
  SummarizeOutlined as SummarizeOutlinedIcon,
} from "@mui/icons-material";
import { UserContext } from "../Hooks/UserContext";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import FailureReportModal from "./ReportModal";

const formatDate = (dateStr) => {
  if (!dateStr) return "N/A";

  const dateObject = new Date(dateStr);

  const options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
    timeZone: 'Europe/Istanbul'
  };

  const formattedDate = dateObject.toLocaleString('en-GB', options);

  return formattedDate;
};

const columns = (navigate, handleOpenModal) => [
  { field: "job_name", headerName: "Job Name", width: 150, cellClassName: "wrapCell" },
  {
    field: "data_name",
    headerName: "Data Name",
    width: 300,
    valueGetter: (params) => params.row.data_name,
    cellClassName: "wrapCell",
  },
  { field: "workflow_name", headerName: "Workflow Name", width: 200, cellClassName: "wrapCell" },
  { field: "processDate", headerName: "Process Date", width: 250, cellClassName: "wrapCell" },
  {
    field: "status",
    headerName: "Status",
    width: 150,
    renderCell: (params) => {
      let statusColor = "";
      let bgColor = "";
      let statusText = "";

      if (params.row.status === "in progress") {
        statusText = "● In Progress";
        statusColor = "blue";
        bgColor = "rgba(0, 0, 255, 0.1)";
      } else if (params.row.status === "break") {
        statusText = "● Failed";
        statusColor = "red";
        bgColor = "rgba(255, 0, 0, 0.1)";
      } else if (params.row.status === "finished") {
        statusText = "● Completed";
        statusColor = "green";
        bgColor = "rgba(0, 255, 0, 0.1)";
      }
      return (
        <span
          style={{
            color: statusColor,
            backgroundColor: bgColor,
            borderRadius: 6,
            padding: "4px 8px",
            fontSize: 12,
          }}
        >
          {statusText}
        </span>
      );
    },
  },
  {
    field: "report",
    headerName: "Report",
    width: 200,
    renderCell: (params) => {
      let icon;
      let buttonLabel = "";
      let iconColor = "";
      let isClickable = false;

      if (params.row.status === "in progress") {
        buttonLabel = "Not Ready";
        icon = <TrendingUpOutlinedIcon fontSize="14px" />;
        iconColor = "blue";
      } else if (params.row.status === "break") {
        buttonLabel = "Failure Report";
        icon = <FeedbackOutlinedIcon fontSize="14px" />;
        iconColor = "red";
        isClickable = true;
      } else if (params.row.status === "finished") {
        buttonLabel = "Ready";
        icon = <SummarizeOutlinedIcon fontSize="14px" />;
        iconColor = "green";
        isClickable = true;
      }

      return (
        <IconButton
          onClick={() => isClickable && params.row.status === "break" ? handleOpenModal(params.row.id) : navigate(`/reports/${params.row.id}`)}
          style={{ color: iconColor, fontSize: 14 }}
          disabled={!isClickable}
        >
          {icon}
          {buttonLabel}
        </IconButton>
      );
    },
  },
];

const useStyles = makeStyles({
  root: {
    height: "100%",
    width: "100%",
  },
  cell: {
    fontSize: 14,
  },
  noData: {
    textAlign: "center",
    fontSize: 16,
    color: "gray",
    padding: "20px",
  },
});

export default function DataTable() {
  const apiUrl = process.env.REACT_APP_API_BASE_URL;
  const [rows, setRows] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedJobId, setSelectedJobId] = useState(null);
  const { token } = useContext(UserContext);
  const classes = useStyles();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchJobs = async () => {
      try {
        const response = await axios.get(`${apiUrl}/jobs/`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const data = response.data;
        const formattedData = data.map((item) => {
          const srrNumbers = item.data.map((info) => info.srrnumber).join(", ");
          
          return {
            id: item.id,
            data_name: srrNumbers,
            workflow_name: item.workflow.name,
            job_name: item.job_name || "unspecified",
            processDate: item.end_date
              ? `${formatDate(item.start_date)} - ${formatDate(item.end_date)}`
              : formatDate(item.start_date),
            status: item.status.toLowerCase(),
          };
        });

        setRows(formattedData);
      } catch (error) {
        console.error("Error fetching jobs:", error);
      }
    };

    fetchJobs();
  }, [token, apiUrl]);

  const handleOpenModal = (jobId) => {
    setSelectedJobId(jobId);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setSelectedJobId(null);
  };

  return (
    <>
      <div className={classes.root}>
        <Banner />
        <DataGrid
          rows={rows}
          columns={columns(navigate, handleOpenModal)}
          pageSize={5}
          rowsPerPageOptions={[5]}
          getRowId={(row) => row.id}
          components={{
            NoRowsOverlay: () => (
              <div className={classes.noData}>No data available</div>
            ),
          }}
        />
      </div>
      {modalOpen && (
        <FailureReportModal
          open={modalOpen}
          handleClose={handleCloseModal}
          jobId={selectedJobId}
        />
      )}
    </>
  );
}
