import React, { useContext, useState, useEffect } from "react";
import { Layout, Menu } from "antd";
import {
  UserOutlined,
    UserAddOutlined,
  MailOutlined,
  InfoCircleOutlined,

} from "@ant-design/icons";
import Homepagecontent from "../components/Homepagecontent";
import logo from "../assets/img/singlecellquestlogo.svg"
import { Link, useNavigate } from "react-router-dom";
import { UserContext } from "../Hooks/UserContext";
const { Content, Sider } = Layout;

const Homepage = () => {
  const [collapsed, setCollapsed] = useState(false);
  const { user } = useContext(UserContext);
  const navigate = useNavigate();
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 500) {
        setCollapsed(true);
      } else {
        setCollapsed(false);
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    if (user) {
      navigate('/dashboard'); 
    }
  }, [user, navigate]);
  return (
    <Layout className="homepage">
      <Sider
        collapsible
        collapsed={collapsed}
        onCollapse={() => setCollapsed(!collapsed)}
        width={230}
        style={{
          overflow: "auto",
          height: "100vh",
          position: "sticky",
          top: 0,
          left: 0,
          backgroundColor: "#cbd2f2"
        }}
      >
  <div className="logo">
  <img src={logo} alt="singlecellquest" className='logosingle'/>
        </div>
        <Menu theme="light" mode="inline" defaultSelectedKeys={["3"]}>
        
          <Menu.Item key="1" icon={<UserOutlined style={{ fontSize: '20px' }} />}>
          <Link to="/login">
            <span className="nav-text">Login</span>
            </Link>
          </Menu.Item>
          <Menu.Item key="2" icon={<UserAddOutlined style={{ fontSize: '20px' }}/>}>
          <Link to="/register">
            <span className="nav-text">Register</span>
            </Link>
          </Menu.Item>
          <Menu.Item key="3" icon={<InfoCircleOutlined style={{ fontSize: '20px' }}/>}>
          <Link to="/">
          {/* buraya tutorial gelecek */}
            <span className="nav-text">Help & Getting Started</span>
            </Link>
          </Menu.Item>
          <Menu.Item key="4" icon={<MailOutlined style={{ fontSize: '20px' }} />}>
          <a href="mailto:info@genfoquest">
    <span className="nav-text">info@genfoquest</span>
  </a>
          </Menu.Item>
        </Menu>
      </Sider>
      <Layout className="homepage">
        <Content style={{  overflow: "hidden", background: "#EFF5FE" }}>
   
            <Homepagecontent />
     
        </Content>
        {/* <Footer style={{ textAlign: "center" }}>
          Ant Design ©2018 Created by Ant UED
        </Footer> */}
      </Layout>
    </Layout>
  );
};

export default Homepage;
