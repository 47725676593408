import React, { useContext, useState, useEffect } from "react";
import { Layout, Menu } from "antd";
import { UserOutlined, MailOutlined } from "@ant-design/icons";
import { MaterialSymbol } from "react-material-symbols";
import "react-material-symbols/rounded";
import logo from "../assets/img/singlecellquestlogo.svg";
import { Link, useNavigate } from "react-router-dom";
import SavedComponent from "../components/SavedComponent";
import axios from "axios";
import { UserContext } from "../Hooks/UserContext";

const { Content, Sider } = Layout;

const SavedWorkflows = () => {
  const navigate = useNavigate();
  const { setUser, setToken } = useContext(UserContext);
  const [collapsed, setCollapsed] = useState(false);
  const apiUrl = process.env.REACT_APP_API_BASE_URL;
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 500) {
        setCollapsed(true);
      } else {
        setCollapsed(false);
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const handleLogout = async () => {
    try {
      const response = await axios.post(
        `${apiUrl}/logout`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.status === 204) {
        setUser(null);
        setToken(null);

        localStorage.clear();
        navigate("/");
      } else {
        console.error("Logout işlemi başarısız oldu:", response);
      }
    } catch (error) {
      console.error("Logout işlemi sırasında hata oluştu:", error);
    }
  };
  return (
    <Layout className="homepage">
      <Sider
        collapsible
        collapsed={collapsed}
        onCollapse={() => setCollapsed(!collapsed)}
        width={230}
        style={{
          overflow: "auto",
          height: "100vh",
          position: "sticky",
          top: 0,
          left: 0,
          backgroundColor: "#cbd2f2",
        }}
      >
        <div className="logo">
          <img src={logo} alt="singlecellquest" className="logosingle" />
        </div>
        <Link to="/dashboard">
          <div
            style={{
              width: "80%",
              color: "white",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginLeft: "10%",
              borderRadius: "10px",
              border: "2px solid white",
              flexDirection: "row",
              marginBottom: "10px",
            }}
          >
            <MaterialSymbol
              style={{ marginRight: "6px" }}
              icon="account_circle"
              size={24}
              fill
            />

            {!collapsed && (
              <h3 style={{ color: "#002140", textAlign: "center" }}>
                {" "}
                My Workspace
              </h3>
            )}
          </div>
        </Link>
        <Menu theme="light" mode="inline" defaultSelectedKeys={["3"]}>
          <Menu.Item
            key="1"
            icon={<MaterialSymbol icon="stylus_note" size={24} fill />}
          >
            <Link to="/design-workflow">
              <span className="nav-text">Design Workflows</span>
            </Link>
          </Menu.Item>
          <Menu.Item
            key="2"
            icon={<MaterialSymbol icon="database" size={24} fill />}
          >
            <Link to="/workflows">
              <span className="nav-text">My Data & Workflows</span>
            </Link>
          </Menu.Item>
          <Menu.Item
            key="3"
            icon={<MaterialSymbol icon="network_node" size={24} fill />}
          >
            <Link to="/jobs">
              <span className="nav-text">Create Job</span>
            </Link>
          </Menu.Item>
          <Menu.Item
            key="4"
            icon={<MaterialSymbol icon="work_history" size={24} fill />}
          >
            <Link to="/run-history">
              <span className="nav-text">Run History</span>
            </Link>
          </Menu.Item>
          <Menu.Item
            key="5"
            icon={<MaterialSymbol icon="lab_profile" size={24} fill />}
          >
            <Link to="/reports">
              <span className="nav-text">Reports</span>
            </Link>
          </Menu.Item>
          <Menu.Item
            key="6"
            icon={<MaterialSymbol icon="credit_card" size={24} fill />}
          >
            <Link to="/credits">
              <span className="nav-text">My Credits</span>
            </Link>
          </Menu.Item>
          <hr style={{ marginBottom: "20%", marginTop: "10%" }}></hr>

          <Menu.Item
            key="7"
            icon={<UserOutlined style={{ fontSize: "20px" }} />}
          >
            <Link to="/profile">
              <span className="nav-text">Profile</span>
            </Link>
          </Menu.Item>
          {/* <Menu.Item key="7" icon={<InfoCircleOutlined style={{ fontSize: '20px' }}/>}>
          <Link to="/">

            <span className="nav-text">Help & Getting Started</span>
            </Link>
          </Menu.Item> */}
          <Menu.Item
            key="8"
            icon={<MailOutlined style={{ fontSize: "20px" }} />}
          >
            <a href="mailto:info@genfoquest">
              <span className="nav-text">info@genfoquest</span>
            </a>
          </Menu.Item>

          <Menu.Item
  key="9"
  icon={<MaterialSymbol icon="logout" size={24} fill />}
  onClick={handleLogout} 
>
  <span className="nav-text">Log out</span>
</Menu.Item>
          <hr style={{ marginBottom: "50%", marginTop: "10%" }}></hr>
        </Menu>
      </Sider>
      <Layout className="homepage">
        <Content style={{ overflow: "hidden", background: "#EFF5FE" }}>
          <SavedComponent />
        </Content>
        {/* <Footer style={{ textAlign: "center" }}>
          Ant Design ©2018 Created by Ant UED
        </Footer> */}
      </Layout>
    </Layout>
  );
};

export default SavedWorkflows;
