import React from 'react';
import { Card, CardMedia } from '@mui/material';
import "./Homepage.css";
import { styled } from '@mui/system';
import Banner from './Banner';
import tutorial from "../assets/videos/scq.mp4";

const StyledCard = styled(Card)(() => ({
  maxWidth: 1000,  
  margin: '20px auto',
}));

// Video kartı bileşeni
const renderVideoCard = (videoSrc) => (
  <StyledCard>
    <CardMedia
      component="video"
      src={videoSrc}
      controls
      autoPlay  
      muted
      style={{ height: '100%', width: '100%' }}  
    />
  </StyledCard>
);

const HomepageContent = () => {
  return (
    <div className='homepage'>
      <Banner />
      <div className='tutorial'>
        {renderVideoCard(tutorial)} 
      </div>
    </div>
  );
};

export default HomepageContent;
