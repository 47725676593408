import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Login from "./pages/Login";
import Register from "./pages/Register";
import Homepage from "./pages/Homepage";
import Dashboard from "./pages/Dashboard";
import ForgotPassword from "./pages/ForgotPassword";
import DesignWorkflow from "./pages/DesignWorkflow";
import SavedWorkflows from "./pages/SavedWorkflows";
import RunHistory from "./pages/RunHistory";
import Profile from "./pages/Profile";
import Report from "./pages/Report";
import Fastqc from "./reports/FastqcReport";
import Fastqc1 from "./reports/FastqcReport1";
import { UserProvider } from './Hooks/UserContext';
import PrivateRoute from './Hooks/PrivateRoute';
import Credit from './pages/Credit';
import DataWorkflow from './pages/DataWorkflow';

function App() {
    return (
        <UserProvider>
            <Router>
                <Routes>
                    <Route path="/login" element={<Login />} />
                    <Route path="/register" element={<Register />} />
                    <Route path="/resetpassword" element={<ForgotPassword />} />
                    <Route path="/" element={<Homepage />} />
                    <Route path="/profile" element={<PrivateRoute><Profile /></PrivateRoute>} />
                    <Route path="/credits" element={<PrivateRoute><Credit /></PrivateRoute>} />
                    <Route path="/dashboard" element={<PrivateRoute><Dashboard /></PrivateRoute>} />
                    <Route path="/design-workflow" element={<PrivateRoute><DesignWorkflow /></PrivateRoute>} />
                    <Route path="/workflows" element={<PrivateRoute><DataWorkflow /></PrivateRoute>} />
                    <Route path="/jobs" element={<PrivateRoute><SavedWorkflows /></PrivateRoute>} />
                    <Route path="/run-history" element={<PrivateRoute><RunHistory /></PrivateRoute>} />
                    <Route path="/reports" element={<PrivateRoute><Report /></PrivateRoute>} />
                    <Route path="/reports/:id" element={<PrivateRoute><Report /></PrivateRoute>} />
                    <Route path="/fastqc-report1" element={<PrivateRoute><Fastqc /></PrivateRoute>} />
                    <Route path="/fastqc-report2" element={<PrivateRoute><Fastqc1 /></PrivateRoute>} />
                </Routes>
            </Router>
        </UserProvider>
    );
}

export default App;