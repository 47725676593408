import React, { useContext, useState } from "react";
import Banner from "./Banner";
import { Button, Input, Select, Modal, Tooltip } from "antd";
import "./DesignPage.css";
import {
  InfoCircleTwoTone,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import { UserContext } from '../Hooks/UserContext';

const { Option } = Select;

const DesignPage = () => {
  const apiUrl = process.env.REACT_APP_API_BASE_URL
  const { token } = useContext(UserContext); 
  const [workflowName, setWorkflowName] = useState("");
  const [qualityControl, setQualityControl] = useState("");
  const [alignment, setAlignment] = useState("");
  const [cluster, setCluster] = useState("");
  const [dataType, setDataType] = useState("");
  const [cellTypeAnnotation, setCellTypeAnnotation] = useState("");
  const maxLength = 25;
  const info = () => {
    Modal.info({
      title: "This is a help documentation",
      content: (
        <div>
          <p>When creating the workflow, choose the data type carefully.</p>
          <p>
            The steps to be processed will be checked according to your data
            type and options will be offered according to your data type.{" "}
          </p>
          <p>When creating the workflow, choose the data type carefully.</p>
          <p>Steps that are not suitable for your data will not be shown.</p>
          <p>Please do not forget to name and save the workflow.</p>
          <p>
            After creating your workflow, please run it by selecting your data
            of the data type you selected from your "Saved Workflows" page.
          </p>
        </div>
      ),
      onOk() {},
    });
  };

  const handleWorkflowNameChange = (e) => {
    setWorkflowName(e.target.value);
  };

  const handleSubmitButtonClick = () => {
    if (!workflowName.trim()) {
      Modal.error({
        title: "Error",
        content: "Please enter a name for your workflow.",
      });
      return; 
    }
  
    const steps = {};
  
    if (qualityControl) {
      steps[1] = {
        name: qualityControl === "fastqc" ? "skip_fastqc" : (qualityControl === "multiqc" ? "skip_multiqc" : ""),
        description: "Description of Quality Control step",
        command: qualityControl ? "false" : "",
        parameters: {
          parameter1: "value1",
          parameter2: "value2",
        },
      };
    }
  
    if (alignment) {
      steps[2] = {
        name: "aligner",
        description: "Description of Alignment step",
        command: alignment === "StarSolo" ? "star" : (alignment === "CellRanger" ? "cellranger" : (alignment === "Kallisto" ? "kallisto" : (alignment === "Salmon" ? "salmon" : ""))),
        parameters: {
          parameter1: "value1",
          parameter2: "value2",
        },
      };
    }
  
    if (cluster) {
      steps[3] = {
        name: "cluster",
        description: "Description of Cluster step",
        command: cluster,
        parameters: {
          parameter1: "value1",
          parameter2: "value2",
        },
      };
    }
  
    if (cellTypeAnnotation) {
      steps[4] = {
        name: "annotation",
        description: "Description of Cell Type Annotation step",
        command: cellTypeAnnotation,
        parameters: {
          parameter1: "value1",
          parameter2: "value2",
        },
      };
    }
  
    // Check if at least one step is present
    if (Object.keys(steps).length === 0) {
      Modal.error({
        title: "Error",
        content: "Please select at least one step for your workflow.",
      });
      return; // Stop execution
    }
  
    const data = {
      name: workflowName,
      description: "Workflow description",
      steps: steps,
    };
  
    const headers = {
      Authorization: `Bearer ${token}`, // Add token to headers
      "Content-Type": "application/json",
    };
  
    fetch(`${apiUrl}/workflows/`, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (response.ok) {
          Modal.success({
            title: "Success",
            content: `Your workflow '${workflowName}' has been saved successfully. You can start the process from the "Saved Workflows" page.`,
            onOk() {
              window.location.reload();
            },
          });
        } else if (response.status === 400) {
          Modal.error({
            title: "Error",
            content: "A workflow with this name already exists. Please choose a different name.",
          });
        } else {
          Modal.error({
            title: "Error",
            content: "An error occurred while saving the workflow. Please try again later.",
          });
        }
      })
      .catch((error) => {
        Modal.error({
          title: "Error",
          content: "An error occurred while saving the workflow. Please try again later.",
        });
      });
  };
  

  return (
    <div>
      <Banner />
      <div className="designpage">
        <div className="doc">
          <Button
            type="secondary"
            size="large"
            shape="circle"
            icon={<QuestionCircleOutlined />}
            onClick={info}
          />
          {/* <Button
            type="secondary"
            size="large"
            shape="circle"
            icon={<PlusCircleOutlined />}
          /> */}
        </div>
        <div className="title">
          <h3>Design Your Workflow</h3>
        </div>
        <div className="nameinput">
          <h4>Name your pipeline* :</h4>
          <Input
            className="inputpipelinename"
            value={workflowName}
            placeholder="Your new workflow name"
            onChange={handleWorkflowNameChange}
            maxLength={maxLength} 
        />
        <div style={{ color: 'grey', fontSize: '12px' }}>
          {workflowName.length}/{maxLength}
        </div>
        </div>
        <div className="pipelinecreating">
          <h4>Create Your Own Workflow</h4>
          <div className="methodsoptions">
            <div className="method">
              <label>Data Type</label>
              <div className="withtooltip">
                <Tooltip
                  title="Choose the data type carefully. The steps will be checked according to your data type, and options will be offered accordingly. 
            FASTQ is a commonly used format for storing DNA sequencing data.
            A count matrix is a matrix that counts the expression levels of genes in specific cells."
                >
                  <Button icon={<InfoCircleTwoTone />} />
                </Tooltip>
                <Select
                  value={dataType}
                  onChange={(value) => setDataType(value)}
                >
                  <Option value="SRA">FastQ</Option>
                  {/* <Option value="countMatrix">Count Matrix</Option> */}
                </Select>
              </div>
            </div>
            <div className="method">
              <label>Quality Control</label>
              <div className="withtooltip">
                <Tooltip
                  title="Select the quality control method that suits your data type. FastQC is a tool used to check the quality of DNA sequencing data. 
              MultiQC is a tool that combines various quality control reports to provide an overview. "
                >
                  <Button icon={<InfoCircleTwoTone />} />
                </Tooltip>
                <Select
                  placeholder="Select Quality Control"
                  value={qualityControl}
                  onChange={(value) => setQualityControl(value)}
                >
                  <Option value="fastqc">FastQC</Option>
                  <Option value="multiqc">MultiQC</Option>
                </Select>
              </div>
            </div>
            <div className="method">
              <label>Alignment</label>
              <div className="withtooltip">
                <Tooltip
                  title="This step aligns the reads to a reference genome.
               StarSolo is a tool used for analyzing single-cell RNA sequencing (scRNA-seq) data.
            CellRanger is a software package commonly used for single-cell analysis.
              Kallisto is a fast alignment tool used for analyzing RNA sequencing data. 
                 Salmon is another alignment tool used for analyzing RNA-seq data.
                "
                >
                  <Button icon={<InfoCircleTwoTone />} />
                </Tooltip>
                <Select
                  placeholder="Select Alignment"
                  value={alignment}
                  onChange={(value) => setAlignment(value)}
                >
                  <Option value="StarSolo">StarSolo</Option>
                  <Option value="CellRanger">CellRanger</Option>
                  <Option value="Kallisto">Kallisto</Option>
                  <Option value="Salmon">Salmon</Option>
                </Select>
              </div>
            </div>
            <div className="method">
              <label>Cluster</label>
              <div className="withtooltip">
                <Tooltip
                  title="Select the clustering method to group similar cells together. This step is essential for downstream analysis.
                SNN: This option allows the user to use the Shared Nearest Neighbor (SNN) clustering method to group similar cells. SNN is a method used to determine cell similarity in single-cell data.
              Leiden: This option allows the user to cluster cells using the Leiden algorithm. Leiden is a commonly used algorithm for clustering in single-cell analysis.
                "
                >
                  <Button icon={<InfoCircleTwoTone />} />
                </Tooltip>
                <Select
                  placeholder="Select Cluster"
                  value={cluster}
                  onChange={(value) => setCluster(value)}
                >
                  <Option value="SNN">SNN</Option>
                  <Option value="Leiden">Leiden</Option>
                </Select>
              </div>
            </div>
            <div className="method">
              <label>Cell Type Annotation</label>
              <div className="withtooltip">
                <Tooltip
                  title="Choose whether you want cell type annotation. Yes (CellTypist): This option allows the user to use the CellTypist tool for cell type annotation. CellTypist is designed to determine cell types in single-cell RNA-seq data.
            No: This option indicates that the user does not want to perform cell type annotation. In this case, no specific cell type will be assigned."
                >
                  <Button icon={<InfoCircleTwoTone />} />
                </Tooltip>
                <Select
                disabled
                  className="selectopt"
                  placeholder="Select Cell Type Annotation"
                  value={cellTypeAnnotation}
                  onChange={(value) => setCellTypeAnnotation(value)}
                >
                  <Option value="Yes">Yes (CellTypist)</Option>
                  <Option value="No">No</Option>
                </Select>
              </div>
            </div>
          </div>
        </div>
        <div className="buttondiv">
          <Button
            style={{
              backgroundColor: "#5871EB",
              borderColor: "#5871EB",
              color: "#FFFFFF",
            }}
            type="primary"
            htmlType="submit"
            size="middle"
            onClick={handleSubmitButtonClick}
          >
            Save
          </Button>
        </div>
      </div>
    </div>
  );
};

export default DesignPage;