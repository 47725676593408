import React, { useContext, useEffect, useState } from "react";
import Banner from "./Banner";
import { Button, Select, Modal, message, Input } from "antd";
import "./DesignPage.css";
import "./SavedComponent.css";
import { QuestionCircleOutlined } from "@ant-design/icons";
import axios from "axios";
import { UserContext } from "../Hooks/UserContext";

const { Option } = Select;

const SavedComponent = () => {
  const apiUrl = process.env.REACT_APP_API_BASE_URL;
  const { token } = useContext(UserContext);
  const [runworkflow, setRunworkflow] = useState(null);
  const [species, setSpecies] = useState("human");
  const [showRundiv, setShowRundiv] = useState(true);
    // const [sraNumber, setSraNumber] = useState("");
  // const [sraNumbers, setSraNumbers] = useState([]); // Next butonu kalktığı için başlangıçta true olarak ayarlandı
  const [runworkflowOptions, setRunworkflowOptions] = useState([]);
  const [workflowSteps, setWorkflowSteps] = useState([]);
  const [dataIds, setDataIds] = useState([]);
  const [downloadedData, setDownloadedData] = useState([]);
  const [jobName, setJobName] = useState("");
  const maxLength = 25;

  useEffect(() => {
    axios
      .get(`${apiUrl}/workflows/`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setRunworkflowOptions(
          response.data.map((workflow) => ({
            value: workflow.id,
            label: workflow.name,
          }))
        );
      })
      .catch((error) => {
        console.error("Error fetching workflows:", error);
      });
  }, [token, apiUrl]);

  useEffect(() => {
    axios
      .get(`${apiUrl}/ncbi/`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        const finishedData = response.data.filter(
          (data) => data.download_status === "finished"
        );
        setDownloadedData(finishedData);
      })
      .catch((error) => {
        console.error("Error fetching downloaded data:", error);
      });
  }, [token, apiUrl]);

  const handleRun = async () => {
    if (!dataIds.length || !species || !runworkflow) {
      message.error(
        "Please select data, species, and workflow before running."
      );
      return;
    }

    const requestData = {
      workflow_id: runworkflow,
      data_id: dataIds,
      status: "in progress",
      job_name: jobName,
    };

    try {
      const response = await axios.post(`${apiUrl}/jobs/`, requestData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      if (response.status === 201) {
        const successMessage = jobName
          ? `${jobName} started successfully with workflow '${runworkflowOptions.find(
              (option) => option.value === runworkflow
            )?.label}'.`
          : `Job started successfully with workflow '${runworkflowOptions.find(
              (option) => option.value === runworkflow
            )?.label}'.`;

        message.success(successMessage, 2, () => {
          window.location.reload();
        });
      } else {
        throw new Error("An error occurred while starting the job.");
      }
    } catch (error) {
      console.error(error);
      message.error(
        "An error occurred while starting the job. Please try again."
      );
    }
  };

  const info = () => {
    Modal.info({
      title: "This is a help documentation",
      content: (
        <div>
          <p>
            On this page, you can run the workflow you created with the data you
            select. First, you need to choose your data. You can select data
            that you previously downloaded from the My Data & Workflow page.
            When you select a workflow, you can review the pipeline flow you
            created and confirm it. After selecting your data and workflow, you
            can start the process by clicking the 'Run' button.
          </p>
        </div>
      ),
      onOk() {},
    });
  };

  const handleSelectSpecies = (value) => {
    setSpecies(value);
  };
  const handleJobNameChange = (e) => {
    setJobName(e.target.value);
  };

  const fetchWorkflowSteps = (workflowId) => {
    axios
      .get(`${apiUrl}/workflows/${workflowId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response.data.steps) {
          const stepsArray = Object.values(response.data.steps).map((step) => ({
            name: step.name || "",
            command: step.command || "",
          }));
          setWorkflowSteps(stepsArray);
        } else {
          setWorkflowSteps([]);
        }
      })
      .catch((error) => {
        console.error("Error fetching workflow steps:", error);
        setWorkflowSteps([]);
      });
  };

  const handleWorkflowChange = (value) => {
    setRunworkflow(value);
    fetchWorkflowSteps(value);
  };

  const renderSteps = () => {
    if (workflowSteps.length === 0) {
      return null;
    }

    return (
      <div className="arrow-steps clearfix">
        {workflowSteps.map((step, index) => {
          let displayStep;

          if (index === 0) {
            if (step.name === "skip_fastqc") {
              displayStep = "FastQC";
            } else if (step.name === "skip_multiqc") {
              displayStep = "MultiQC";
            } else {
              displayStep = step.name;
            }
          } else if (step.command && step.command.includes("star")) {
            displayStep = "StarSolo";
          } else if (
            step.command.includes("Yes") ||
            step.command.includes("No")
          ) {
            displayStep = `Cell type annotation: ${step.command}`;
          } else {
            displayStep = step.command;
          }

          return (
            <div className="step" key={index}>
              <span>
                {`Step ${index + 1}:`} {displayStep}
              </span>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div className="savedcomponent">
      <Banner />
      <div className="savedcomponentpage">
        <div className="topedit">
          <div className="doc">
            <h5
              style={{
                fontSize: "14px",
                textAlign: "center",
                fontWeight: "500",
                color: "#103b82",
              }}
            >
              {" "}
            </h5>
          </div>
          <div className="doc">
            <Button
              type="secondary"
              size="large"
              shape="circle"
              icon={<QuestionCircleOutlined />}
              onClick={info}
            />
          </div>
        </div>

        <div className="title">
          <h3>Create Job</h3>
        </div>
        <div>
          <h4>Specify a Job Name (optional)</h4>
          <h5 style={{ color: "grey" }}>
            This job name will also appear as the report name on the report
            page. If you choose not to provide a name, the report will display
            the pipeline name along with the date.
          </h5>
          <div className="nameinput">
            <Input
              className="inputpipelinename"
              value={jobName}
              placeholder="Your job name"
              onChange={handleJobNameChange}
              maxLength={maxLength} // This sets the maximum length in the input field
            />
            <div style={{ color: "grey", fontSize: "12px" }}>
              {jobName.length}/{maxLength}
            </div>
          </div>
        </div>
        <div>
          <h4>Select Data to Run </h4>
          <h5 style={{ color: "grey" }}>
            Only the downloaded data is shown below. If the data you want to use
            is not on the list, please check it on My Data & Workflows Page.
          </h5>
        </div>
        <div className="dataupload">
          <div className="datadiv">
            <div className="dataupload">
              <Select
                mode="multiple"
                placeholder="Select Downloaded Data"
                onChange={(value) => setDataIds(value)} // 'value' will be an array of selected IDs
                value={dataIds}
                style={{ width: "30vw", marginBottom: 10 }}
              >
                {downloadedData.map((data) => (
                  <Option key={data.id} value={data.id}>
                    {data.srrnumber}
                  </Option>
                ))}
              </Select>
            </div>
          </div>
        </div>
        <div className="dataselect">
          <h4>Select Species</h4>
        </div>
        <div className="dataupload">
          <Select
            placeholder="Select species"
            value={species}
            style={{ width: "30vw" }}
            onChange={handleSelectSpecies}
          >
           <Option value="human">Homo sapiens </Option>
           <Option value="mouse">Mus musculus</Option>
         
          </Select>
        </div>

        <div className="dataselect">
          <h4>Select Workflow to Run</h4>
        </div>

        <div className="dataupload">
          <Select
            placeholder="Select Workflow"
            value={runworkflow}
            onChange={handleWorkflowChange}
            style={{ width: "30vw" }}
          >
            {runworkflowOptions.map((workflow) => (
              <Option key={workflow.value} value={workflow.value}>
                {workflow.label}
              </Option>
            ))}
          </Select>
        </div>
        <div>{renderSteps()}</div>

        <div className="savedbuttondiv">
          <Button
            className="buttonstyles"
            type="primary"
            onClick={handleRun}
            style={{ background: "#103b82", borderRadius: "8px" }}
          >
            Run
          </Button>
        </div>
      </div>
    </div>
  );
};

export default SavedComponent;
