import React, { useState, useContext } from 'react';
import axios from 'axios';
import { UserContext } from '../Hooks/UserContext';
import "./ProfilePage.css";
import Banner from './Banner';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import { Row, Col, Card, Descriptions, Input, Button, message } from "antd";
import { useNavigate } from 'react-router-dom';

const ProfilePage = () => {
  const { user, token } = useContext(UserContext); 
  const [profileData, setProfileData] = useState({ ...user });
  const [initialProfileData, setInitialProfileData] = useState({ ...user });
  const [isEditing, setIsEditing] = useState(false);
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_BASE_URL;

  const handleSave = async () => {
    try {
      await axios.put(`${apiUrl}/users/${user.id}`, profileData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setInitialProfileData(profileData);
      setIsEditing(false);
      message.success('Profile updated successfully!'); // Show success message
      setTimeout(() => window.location.reload(), 2000); // Refresh page after 2 seconds
    } catch (error) {
      console.error('Failed to update profile:', error);
      message.error('Failed to update profile. Please try again.'); // Show error message
    }
  };


  const handleCancel = () => {
    setProfileData(initialProfileData); 
    setIsEditing(false);
  };

  const handleChange = (e) => {
    setProfileData({ ...profileData, [e.target.name]: e.target.value });
  };

  const handleBuyCredits = () => {
    navigate('/credits'); 
  };

  return (
    <div>
      <Banner />
      <div className="profilepage">
        <Row gutter={[1, 0]}>
          <Col span={24} md={20}>
            <Card
              bordered={false}
              title={<h6 className="profileinfo">Profile Information</h6>}
              bodyStyle={{ paddingTop: 10, paddingBottom: 20 }}
            >
              <div style={{ display: "flex", alignItems: "center", cursor: "pointer" }} onClick={handleBuyCredits}>
                Do you need more credits? Click here and buy!
                <CurrencyExchangeIcon style={{ marginLeft: "10px", color: '#1890ff' }} />
              </div>
              <hr style={{ opacity: "0.2" }} />

              <Descriptions title="">
                <Descriptions.Item label="Full Name" span={3}>
                  {user.first_name} {user.last_name}
                </Descriptions.Item>
                <Descriptions.Item label="Mobile" span={3}>
                  {isEditing ? (
                    <Input
                      name="mobile"
                      value={profileData.mobile}
                      onChange={handleChange}
                    />
                  ) : (
                    user.mobile
                  )}
                </Descriptions.Item>
                <Descriptions.Item label="Email" span={3}>
                  {user.email}
                </Descriptions.Item>
                <Descriptions.Item label="Address" span={3}>
                  {isEditing ? (
                    <Input
                      name="address"
                      value={profileData.address}
                      onChange={handleChange}
                    />
                  ) : (
                    user.address
                  )}
                </Descriptions.Item>
                <Descriptions.Item label="Credits" span={3}>
                  {user.credit}
                </Descriptions.Item>
              </Descriptions>

              {isEditing ? (
                <div>
                  <Button type="primary" onClick={handleSave}>
                    Save
                  </Button>
                  <Button
                    style={{ marginLeft: '10px' }}
                    onClick={handleCancel} // Reset and exit editing mode
                  >
                    Cancel
                  </Button>
                </div>
              ) : (
                <Button type="primary" onClick={() => setIsEditing(true)}>
                  Edit Profile
                </Button>
              )}
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default ProfilePage;
