import React, { useState } from "react";
import { Input, Button, Form, Alert, Modal } from "antd";
import "./Login.css";
import logo from "../assets/img/singlecellquestlogo.svg";
import genfoquestlogo from "../assets/img/genfoquestlogo.svg";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import termsAndConditionsPdf from "../assets/pdf/termsandconditions.pdf";
//t&c pdf ekle
const Register = () => {
  const apiUrl = process.env.REACT_APP_API_BASE_URL;
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
  };

  const handleSubmit = async (values) => {
    if (!isChecked) {
      setError("You must agree to the terms and conditions to register.");
      return;
    }
    try {
      const response = await axios.post(`${apiUrl}/users/`, {
        first_name: values.firstName,
        last_name: values.lastName,
        email: values.email,
        password: values.password,
      });

      if (response.status === 201) {
        Modal.success({
          content: "Your account has been successfully created!",
          onOk: () => {
            setTimeout(() => {
              navigate("/login");
            }, 800);
          },
        });
      } else {
        setError("An error occurred. Please try again later.");
      }
    } catch (error) {
      if (error.response && error.response.status === 409) {
        setError("Email is already registered.");
      } else {
        setError("An error occurred. Please try again later.");
      }
    }
  };

  return (
    <div className="main">
      <div className="logo-container-dash">
        <Link to="https://genfoquest.com/">
          <img
            src={genfoquestlogo}
            alt="genfoquestlogo"
            className="logogenfoquest"
          />
        </Link>
      </div>
      <div className="leftsidelogo">
        <img src={logo} alt="singlecellquest" className="logosingled" />
      </div>

      <div className="rightsidelogin">
        <div className="loginform">
          <h3 className="title"> Welcome to Single Cell Quest</h3>
          <h4 className="subtitle"> We Simplify Bioinformatics for You</h4>

          <Link to="/login" style={{ textDecoration: "none" }}>
            <h5 className="registerbutton">Already have an account? Login!</h5>
          </Link>

          <div className="formforlogin">
            <Form
              name="basic"
              initialValues={{ remember: true }}
              onFinish={handleSubmit}
              autoComplete="off"
              style={{ width: "100%" }}
              layout="vertical"
            >
              {error && (
                <Form.Item>
                  <Alert
                    message={error}
                    type="error"
                    showIcon
                    closable
                    onClose={() => setError(null)}
                  />
                </Form.Item>
              )}
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Form.Item
                  label="First Name"
                  name="firstName"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your first name!",
                    },
                  ]}
                  style={{ marginRight: "8px", flex: 1 }}
                >
                  <Input size="large" />
                </Form.Item>
                <Form.Item
                  label="Last Name"
                  name="lastName"
                  rules={[
                    { required: true, message: "Please enter your last name!" },
                  ]}
                  style={{ flex: 1 }}
                >
                  <Input size="large" />
                </Form.Item>
              </div>
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  {
                    required: true,
                    message: "Please enter your email address!",
                  },
                  {
                    type: "email",
                    message: "Please enter a valid email address!",
                  },
                ]}
              >
                <Input size="large" autoCapitalize="none" />
              </Form.Item>

              <Form.Item
                label="Create Password"
                name="password"
                rules={[
                  { required: true, message: "Please enter your password!" },
                ]}
              >
                <Input.Password size="large" />
              </Form.Item>

              <Form.Item
                label="Confirm Password"
                name="repassword"
                rules={[
                  { required: true, message: "Please confirm your password!" },
                ]}
              >
                <Input.Password size="large" />
              </Form.Item>

              <Form.Item>
                <div className="check">
                  <label style={{ display: "flex", alignItems: "center" }}>
                    <input
                      type="checkbox"
                      checked={isChecked}
                      onChange={handleCheckboxChange}
                      style={{ transform: "scale(1.5)", marginRight: "10px" }}
                    />
                    <a
                      style={{ color: "black", cursor:"pointer",  }}
                      href={termsAndConditionsPdf}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      I agree to terms & conditions
                    </a>
                  </label>
                </div>
              </Form.Item>

              <Form.Item>
                <Button
                  style={{
                    backgroundColor: "#5871EB",
                    borderColor: "#5871EB",
                    color: "#FFFFFF",
                  }}
                  type="primary"
                  htmlType="submit"
                  size="large"
                  block
                >
                  Register
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
