import React, { useContext } from 'react';
import { UserContext } from '../Hooks/UserContext';
import Banner from './Banner';
import "./Dashboard.css";
import bioinfoimg from "../assets/img/bioinfo.svg";

const Dashboardcomponent = () => {
  const { user } = useContext(UserContext); 
  return (
    <div className='homepage'>
      <Banner/>
      <div className='dashboard'> 
        <div className='content'> 
          <div className='contexttext'>
          <h4 className='welcometext'> Welcome, {user.first_name} {user.last_name} </h4>
            <p className='pagetext'> Unlock the power of single-cell sequencing data processing with the Single Cell Quest platform. Our platform enables you to handle single-cell sequencing data under one roof without the need for specialized analysis expertise, high-end hardware, or extensive storage capacity.</p>
            <p className='pagetext'> We have streamlined the complex analysis steps for you, allowing you to focus on the results. With Single Cell Quest, you can seamlessly navigate through the intricacies of single-cell sequencing analysis, benefiting from our expertise and robust infrastructure. Experience the convenience of consolidated processing and concentrate on the insights that matter.</p>
            <p className='pagetext'> Explore the world of single-cell analysis effortlessly – all at your fingertips with Single Cell Quest!</p>
          </div>
          <div className='contentimage'>
            <img alt="" src={bioinfoimg} className='bioinfoimg' />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboardcomponent;
