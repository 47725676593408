import { useContext, useEffect, useState } from "react";
import { Input, Button, Form, Alert } from 'antd';
import { Link, useNavigate } from "react-router-dom";
import axios from 'axios'; 
import { UserContext } from '../Hooks/UserContext';
import logo from "../assets/img/singlecellquestlogo.svg";
import genfoquestlogo from "../assets/img/genfoquestlogo.svg";
import "./Login.css";

const Login = () => {
  const apiUrl = process.env.REACT_APP_API_BASE_URL;
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const { setUser, setToken, user } = useContext(UserContext);

  useEffect(() => {
    if (user) {
      navigate('/dashboard'); // Kullanıcı giriş yaptıysa dashboard'a yönlendir
    }
  }, [user, navigate]);

  const handleSubmit = async (values) => {
    try {
      const formData = new URLSearchParams();
      formData.append('username', values.email);
      formData.append('password', values.password);
  
      const response = await axios.post(`${apiUrl}/login`, formData, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      });

      if (response.status === 200) {
        const token = response.data.access_token;
        setToken(token);
        setUser(response.data.user);
        localStorage.setItem("token", token);
        navigate('/dashboard');
      } else {
        setError("Please check your information and try again.");
      }
    } catch (error) {
      console.error("Login error:", error);
      setError(error.response?.status === 403 ? 
        "Please check your information and try again." : 
        'An error occurred, please try again later.');
    }
  };

  return (
    <div className='main'>
      <div className='logo-container-dash'>
        <Link to="https://genfoquest.com/">
          <img src={genfoquestlogo} alt="genfoquestlogo" className='logogenfoquest' />
        </Link>
      </div>
      <div className='leftsidelogo'> 
        <img src={logo} alt="singlecellquest" className='logosingled'/>
      </div>
      <div className='rightsidelogin'>
        <div className='loginform'>
          <h3 className='title'>Welcome to Single Cell Quest</h3>
          <h4 className='subtitle'>We Simplify Bioinformatics for You</h4>
          <Link to="/register" style={{ textDecoration: 'none' }}>
            <h5 className='registerbutton'>Don’t you have an account? Register!</h5>
          </Link>
          <div className='formforlogin'>
            <Form
              name="basic"
              initialValues={{ remember: true }}
              onFinish={handleSubmit}
              autoComplete="off"
              style={{ width: "100%" }}
              layout="vertical"
            >
              <Form.Item
                label="Email"
                name="email"
                rules={[{ required: true, message: 'Please enter your email address!' }]}
              >
                <Input size='large' autoCapitalize="none"/>
              </Form.Item>
              <Form.Item
                label="Password"
                name="password"
                rules={[{ required: true, message: 'Please enter your password!' }]}
              >
                <Input.Password size='large'/>
              </Form.Item>
              <Form.Item>
                <Button style={{ backgroundColor: '#5871EB', borderColor: '#5871EB', color: '#FFFFFF'}} type="primary" htmlType="submit" size='large' block>
                  Login
                </Button>
              </Form.Item>
              {error && (
                <Form.Item>
                  <Alert
                    message={error}
                    type='error'
                    showIcon
                    closable
                    onClose={() => setError(null)}
                  />
                </Form.Item>
              )}
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
