import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Button, Select, Spin } from "antd";
import JSZip from "jszip"; // Import JSZip for handling ZIP files
import { UserContext } from "../Hooks/UserContext";
import Banner from "./Banner";
import "./AllReport.css";
import { saveAs } from "file-saver"; 

const AllReport = ({ jobId: propJobId }) => {
  const { Option } = Select;
  const apiUrl = process.env.REACT_APP_API_BASE_URL;
  const { token } = useContext(UserContext);
  const { jobId: routeJobId } = useParams();
  const [jobs, setJobs] = useState([]);
  const [jobId, setJobId] = useState(propJobId || routeJobId || null);
  const [svgContents, setSvgContents] = useState([]);

  const reportNames = [
    "UMI count distributions across cells for each sample",
    "Detected gene count distribution across cells for each sample",
    "UMAP projection of cells colored by sample origin",
    "UMAP projection of cells colored by cluster indentity",
  ];

  const formatDate = (dateStr) => {
    if (!dateStr) return "unknown";

    const dateObject = new Date(dateStr);

    const options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
      timeZone: "Europe/Istanbul",
    };

    const formattedDate = dateObject.toLocaleString("en-GB", options);

    return formattedDate;
  };

  const adjustViewBox = (svgContent, index) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(svgContent, "image/svg+xml");
    const svgElement = doc.querySelector("svg");
  
    if (svgElement) {
      // İlk iki plot için 0 0 600 600, son iki plot için 0 0 800 800
      if (index < 2) {
        svgElement.setAttribute("viewBox", "0 0 800 700");
        svgElement.setAttribute("width", "800");
        svgElement.setAttribute("height", "800");
     
      } else {
        svgElement.setAttribute("viewBox", "0 0 600 600");
        svgElement.setAttribute("width", "600");
        svgElement.setAttribute("height", "600");
      }
      
      svgElement.setAttribute("preserveAspectRatio", "xMidYMid meet");
    }
  
    const serializer = new XMLSerializer();
    return serializer.serializeToString(doc);
  };
  
  const fetchJobs = async () => {
    try {
      const response = await axios.get(`${apiUrl}/jobs/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const filteredJobs = response.data.filter(
        (job) => job.status === "finished"
      );
      setJobs(filteredJobs);
    } catch (error) {
      console.error("Error fetching jobs:", error);
    }
  };

  const fetchReport = async (id) => {
    setSvgContents([]);
    try {
      const response = await axios.get(`${apiUrl}/result/${id}/umap`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        responseType: "blob",
      });
  
      const zip = await JSZip.loadAsync(response.data);
      const svgFiles = Object.keys(zip.files).filter((filename) =>
        filename.endsWith(".svg")
      );
  
      const svgs = await Promise.all(
        svgFiles.map(async (filename, index) => {
          const file = await zip.file(filename).async("text");
          return adjustViewBox(file, index);
        })
      );
  
      setSvgContents(svgs);
    } catch (error) {
      console.error("Error fetching report:", error);
    }
  };
  

  const handleJobSelect = (value) => {
    setJobId(value);
    fetchReport(value);
  };

  const downloadSvg = (svgContent, fileName = "report.svg") => {
    const blob = new Blob([svgContent], { type: "image/svg+xml" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const downloadAllSvgs = () => {
    const zip = new JSZip();
    svgContents.forEach((svg, index) => {
      const fileName = `report_${index + 1}.svg`;
      zip.file(fileName, svg);
    });

    zip.generateAsync({ type: "blob" }).then((content) => {
      saveAs(content, "reports.zip");
    });
  };
  useEffect(() => {
    fetchJobs();
    if (jobId) {
      fetchReport(jobId);
    }
  }, [jobId]);

  return (
    <div>
      <Banner />
      <div className="reportpage">
        <div className="title">
          <h3>Reports</h3>
        </div>
        <div className="reportjob">
          <h4>
            Please select the pipeline name and date of the process for which
            you want to see the report.
          </h4>
          <div className="selectjob">
            <Select
              placeholder="Select report"
              value={jobId || undefined}
              size="large"
              onChange={handleJobSelect}
            >
              {jobs.map((job) => (
                <Option key={job.id} value={job.id}>
                  {job.job_name
                    ? job.job_name
                    : `${job.workflow.name} ${formatDate(
                        job.start_date
                      )} - ${formatDate(job.end_date)}`}
                </Option>
              ))}
            </Select>
          </div>
        </div>
        {svgContents.length > 0 && (
          <div className="download-all">
            <Button
              onClick={downloadAllSvgs}
              type="primary"
              style={{ marginBottom: "20px" }}
            >
              Download All SVGs
            </Button>
          </div>
        )}
        {jobId && (
          <div className="svg-reports">
            {svgContents.length > 0 ? (
              svgContents.map((svg, index) => (
                <div key={index} className="svg-item">
                  <h4 className="svg-title">
                    {reportNames[index] || `Report ${index + 1}`}
                  </h4>
                  <div
                    className="svg-content"
                    dangerouslySetInnerHTML={{ __html: svg }}
                  />
                  <Button
                    onClick={() => downloadSvg(svg, `report_${index + 1}.svg`)}
                    type="primary"
                    style={{
                      marginTop: "10px",
                      background: "ButtonText",
                      marginBottom: "20px",
                    }}
                  >
                    Download
                  </Button>
                </div>
              ))
            ) : (
              <div className="loading-spinner">
                <Spin size="large" />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default AllReport;
