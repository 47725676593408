import React, { useContext, useEffect, useState } from "react";
import Banner from "./Banner";
import { Button, Select, Modal, message, Radio, Table } from "antd";
import "./DesignPage.css";
import "./SavedComponent.css";
import { QuestionCircleOutlined } from "@ant-design/icons";
import axios from "axios";
import { UserContext } from "../Hooks/UserContext";
import TextArea from "antd/es/input/TextArea";

const { Option } = Select;

const DataWorkflowPage = () => {
  const apiUrl = process.env.REACT_APP_API_BASE_URL;
  const { token } = useContext(UserContext); 
  const [runworkflow, setRunworkflow] = useState(null);
  const [dataType, setDataType] = useState("workflows"); 
  // const [sraNumber, setSraNumber] = useState("");
  const [sraNumbers, setSraNumbers] = useState([]); 
  const [runworkflowOptions, setRunworkflowOptions] = useState([]);
  const [workflowSteps, setWorkflowSteps] = useState([]);
  const [dataIds, setDataIds] = useState([]); 
  const [downloadedData, setDownloadedData] = useState([]);
  const [bulkSraInput, setBulkSraInput] = useState(""); // For bulk input
  useEffect(() => {
    if (dataType === "workflows") {
      axios
        .get(`${apiUrl}/workflows/`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          setRunworkflowOptions(
            response.data.map((workflow) => ({
              value: workflow.id,
              label: workflow.name,
            }))
          );
        })
        .catch((error) => {
          console.error("Error fetching workflows:", error);
        });
    }
  }, [token, apiUrl, dataType]);

  useEffect(() => {
    if (dataType === "data") {
      axios
        .get(`${apiUrl}/ncbi/`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          setDownloadedData(response.data);
          console.log(response.data)
          // console.log(response.data)
        })
        .catch((error) => {
          console.error("Error fetching downloaded data:", error);
        });
    }
  }, [token, apiUrl, dataType]);

  const handleDataTypeChange = (e) => {
    setDataType(e.target.value);
    setWorkflowSteps([]);
    setRunworkflow(null);
  };

  const fetchWorkflowSteps = (workflowId) => {
    axios
      .get(`${apiUrl}/workflows/${workflowId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response.data.steps) {
          const stepsArray = Object.values(response.data.steps).map(
            (step) => ({
              name: step.name || "",
              command: step.command || ""
            })
          );
          setWorkflowSteps(stepsArray);
        } else {
          setWorkflowSteps([]);
        }
      })
      .catch((error) => {
        console.error("Error fetching workflow steps:", error);
        setWorkflowSteps([]); 
      });
  };

  const handlePull = async () => {
    if (sraNumbers.length === 0) {
      message.error("Please enter at least one valid SRR number before clicking Use Data.");
      return;
    }
  
    try {
      const response = await axios.post(
        `${apiUrl}/ncbi/fetch_data`,
        JSON.stringify({ srrnumber: sraNumbers }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      
      
      if (response.request.status === 201) {
        const pulldata = response.data;
        setDataIds((prevIds) => [...prevIds, pulldata.id]);
        message.info("Data download started. This may take a while. You can check the status from the table below.");
        
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      } else {
        throw new Error("An error occurred while fetching data.");
      }
    } catch (error) {
      console.error(error);
      message.error("An error occurred while fetching data. Please try again later.");
    }
  };
  

const handleAddSraNumber = () => {
  const numbers = bulkSraInput
    .split(/[\s,]+/)  
    .filter(Boolean); 
  const newNumbers = numbers.filter((number) => !sraNumbers.includes(number));
  setSraNumbers([...sraNumbers, ...newNumbers]);
  setBulkSraInput(""); 
};


  const handleDeleteSraNumber = (number) => {
    setSraNumbers(sraNumbers.filter((num) => num !== number));
  };

  const handleWorkflowChange = (value) => {
    setRunworkflow(value);
    fetchWorkflowSteps(value); 
  };

  const showHelp = () => {
    Modal.info({
      title: "This is a help documentation",
      content: (
        <div>
          <h4>Workflow Selection:</h4>
          <p>
            Select "My Workflows" to choose from existing workflows. You can view the steps involved in the selected workflow and understand the commands executed at each step.
          </p>
          <h4>Data Download:</h4>
          <p>
            Select "My Data" to enter SRR numbers and initiate data download. You can view the download status in the table below. Make sure to enter valid SRR numbers.
          </p>
        </div>
      ),
      onOk() {},
    });
  };

  const renderSteps = () => {
    if (workflowSteps.length === 0) {
      return null;
    }
  
    return (
      <div className="arrow-steps clearfix">
        {workflowSteps.map((step, index) => {
          let displayStep;
  
          if (index === 0) {
            if (step.name === "skip_fastqc") {
              displayStep = "FastQC";
            } else if (step.name === "skip_multiqc") {
              displayStep = "MultiQC";
            } else {
              displayStep = step.name;
            }
          } else if (step.command && step.command.includes("star")) {
            displayStep = "StarSolo";
          } else if (step.command.includes("Yes") || step.command.includes("No")) {
            displayStep = `Cell type annotation: ${step.command}`;
          } else {
            displayStep = step.command;
          }
  
          return (
            <div className="step" key={index}>
              <span>
                {`Step ${index + 1}:`} {displayStep}
              </span>
            </div>
          );
        })}
      </div>
    );
  };

  const renderTable = () => {
    const columns = [
      {
        title: "SRR Number",
        dataIndex: "srrnumber",
        key: "srrnumber",
      },
      {
        title: "Download Status",
        dataIndex: "download_status",
        key: "download_status",
        render: (status) => {
          let color;
          let text;
          
          if (status === "break") {
            text = "Failed";
            color = "red";
          } else if (status) {
            text = status.charAt(0).toUpperCase() + status.slice(1);
            switch (status.toLowerCase()) {
              case "finished":
                color = "green";
                break;
              case "failed":
                color = "red";
                text = "Failed";
                break;
              case "in progress":
                color = "orange";
                text = "In Progress";
                break;
              default:
                color = "gray";
                text = "Pending";
            }
          } else {
            text = "Pending";
            color = "gray";
          }
  
          return <span style={{ color }}>{text}</span>;
        },
      },
      {
        title: "Download Date",
        dataIndex: "created_at",
        key: "created_at",
        render: (date) => {
          if (!date) return "N/A";
  
          const dateObject = new Date(date);
          const options = {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            hour12: false,
            timeZone: 'Europe/Istanbul'
          };
  
          return dateObject.toLocaleString('en-GB', options);
        },
        sorter: (a, b) => new Date(b.created_at) - new Date(a.created_at), 
        defaultSortOrder: 'ascend', 
        showSorterTooltip:false
      },
    ];
  
    return <Table dataSource={downloadedData} columns={columns} rowKey="id" />;
  };
  
  return (
    <div>
      <Banner />
      <div className="designpage">
        <div className="topedit">
          <div className="doc">
            <h5
              style={{
                fontSize: "14px",
                textAlign: "center",
                fontWeight: "500",
                color: "#103b82",
              }}
            >
              {" "}
            </h5>
          </div>
          <div className="doc">
            <Button
              type="secondary"
              size="large"
              shape="circle"
              icon={<QuestionCircleOutlined />}
              onClick={showHelp}
            />
          </div>
        </div>

        <div className="title">
          <h3>My Downloaded Data and Created Workflows</h3>
        </div>

        <div className="radio-group">
          <Radio.Group onChange={handleDataTypeChange} value={dataType}>
            <Radio value="workflows">My Workflows</Radio>
            <Radio value="data">My Data</Radio>
          </Radio.Group>
        </div>

        {dataType === "data" ? (
          <div className="data-form">
          <h3>Download Data by SRR Number <small>(Only Single Cell Data)</small></h3>


            <div className="sra-number-input">
            <div className="sra-text-area">
            <TextArea
                rows={4}
                name="sranumber"
                placeholder="Enter SRR numbers separated by spaces, new lines, commas or add one by one"
                value={bulkSraInput}
                onChange={(e) => setBulkSraInput(e.target.value)}
              />
                        </div>
              <Button onClick={handleAddSraNumber}>Add SRR Numbers</Button>
            </div>

            <div className="added-sra-numbers">
              {sraNumbers.map((number, index) => (
                <div key={index} className="sra-number-item">
                  {number}
                  <Button
                    type="link"
                    danger
                    onClick={() => handleDeleteSraNumber(number)}
                  >
                    Remove
                  </Button>
                </div>
              ))}
            </div>

            <div className="download-actions">
              <Button type="primary" onClick={handlePull}>
                Download Data
              </Button>
            </div>

            <h3>My Downloaded Data</h3>
            {renderTable()}
          </div>
        ) : (
          <div className="workflow-form">
            <h3>Select a Workflow</h3>
            <Select
              style={{ width: "100%" }}
              value={runworkflow}
              onChange={handleWorkflowChange}
              placeholder="Select a workflow"
            >
              {runworkflowOptions.map((option) => (
                <Option key={option.value} value={option.value}>
                  {option.label}
                </Option>
              ))}
            </Select>

            <div className="workflow-steps">{renderSteps()}</div>
          </div>
        )}
      </div>
    </div>
  );
};

export default DataWorkflowPage;
