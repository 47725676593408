import React, { useState } from 'react'
import { Input, Button, Form } from 'antd';
import "./Login.css"
import logo from "../assets/img/singlecellquestlogo.svg"
import genfoquestlogo from "../assets/img/genfoquestlogo.svg"
import { Link, useNavigate } from "react-router-dom";
import { Alert } from 'antd';

// resetpage hazırlanacak
const ForgotPassword = () => {
    const navigate = useNavigate();
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);

    const handleSubmit = async (values) => {
        // Implement your logic for validating and sending reset link
        if (values.email === 'test@gmail.com') {
          // Mock success case, in a real scenario, this would be an API call
          setSuccess(true);
        } else {
          setError('Account information is incorrect, please check');
        }
      };
  return (
    <div className='main'>
     <div className='logo-container'>
     <Link to="/" > 
    <img src={genfoquestlogo} alt="genfoquestlogo" className='logogenfoquest'/> 
</Link>
    </div>
    <div className='leftsidelogo'> 
    <img src={logo} alt="singlecellquest" className='logosingle'/>
    </div>

    <div className='rightsidelogin'> 
    <div className='loginform'>
    <h3 className='title'> Welcome to SingleCellQuest</h3>
    <h4 className='subtitle'> We Simplify Bioinformatics for You</h4>


    <h5 className='registerbutton'>
       Forgot your password ? </h5>
  
    <div className='formforlogin'>
    <h5> Please enter the e-mail address of the account whose password you want to reset.</h5>
    <Form
            name="basic"
        
            initialValues={{
              remember: true,
            }}
           onFinish={handleSubmit}
            autoComplete="off"
            style={{ width: "100%" }} 
            layout="vertical"
          >
             <Form.Item
             label="Email"
              name="email"
              rules={[
                {
                  required: true,
                  message: 'Please enter your email adress!',
                },
              ]}
            >
              <Input placeholder="" size='large'/>
            </Form.Item>
            <Form.Item>
              <Button style={{ backgroundColor: '#5871EB', borderColor: '#5871EB', color: '#FFFFFF'}} type="primary" htmlType="submit" size='large' block='true'>
              Request Reset Link
              </Button>
            </Form.Item>

            {error && (
          <Form.Item>
            <Alert
              message={error}
              type='error'
              showIcon
              closable
              onClose={() => setError(null)}
            />
          </Form.Item>
        )}

        {success && (
          <Form.Item>
            <Alert
              message='Password reset link sent successfully!'
              type='success'
              showIcon
              closable
              onClose={() => setSuccess(false)}
            />
          </Form.Item>
        )}
          </Form>
        </div>
    </div>
    </div>
    </div>
  )
}

export default ForgotPassword

