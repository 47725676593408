import React, { useContext, useEffect, useState, useRef } from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';
import JSZip from 'jszip';
import { UserContext } from '../Hooks/UserContext';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  maxHeight: '80%',
  overflowY: 'auto',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const FailureReportModal = ({ open, handleClose, jobId }) => {
  const [htmlContent, setHtmlContent] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const apiUrl = process.env.REACT_APP_API_BASE_URL;
  const { token } = useContext(UserContext);
  const iframeRef = useRef(null);

  // IFrame içeriğine erişip istenilen class'ı display none yapma
  useEffect(() => {
    if (htmlContent && iframeRef.current) {
      const handleIframeLoad = () => {
        const iframeDocument = iframeRef.current.contentDocument || iframeRef.current.contentWindow.document;
        const samp = iframeDocument.querySelector('samp');
        const nav = iframeDocument.querySelector('nav');

        if (nav) {
          nav.style.display = 'none';
        } else {
          console.error("Navbar element not found.");
        }

        if (samp) {
          samp.style.display = 'none';
        } else {
          console.error("Samp element not found.");
        }
      };

      iframeRef.current.addEventListener('load', handleIframeLoad);

      return () => {
        if (iframeRef.current) {
          iframeRef.current.removeEventListener('load', handleIframeLoad);
        }
      };
    }
  }, [htmlContent]);

  useEffect(() => {
    if (open && jobId) {
      const fetchReport = async () => {
        setLoading(true);
        setError(null);
        try {
          const response = await axios.get(`${apiUrl}/result/${jobId}/umap`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            responseType: 'blob',
          });

          if (response.data.size === 0) {
            throw new Error('Received empty zip file');
          }

          const zip = await JSZip.loadAsync(response.data);
          const fileNames = Object.keys(zip.files);
          const htmlFileName = fileNames.find(fileName => fileName.endsWith('.html'));

          if (htmlFileName) {
            const htmlFile = await zip.file(htmlFileName).async('string');
            setHtmlContent(htmlFile);
          } else {
            throw new Error('HTML file not found in zip.');
          }
        } catch (error) {
          setError(`Error loading report, try again later.`);
          console.error('Error fetching failure report:', error);
        } finally {
          setLoading(false);
        }
      };

      fetchReport();
    } else {
      setHtmlContent(null);
      setError(null);
    }
  }, [open, jobId, apiUrl, token]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box sx={style}>
        <IconButton
          edge="end"
          color="inherit"
          onClick={handleClose}
          aria-label="close"
          sx={{ position: 'absolute', top: 8, right: 8 }}
        >
          <CloseIcon />
        </IconButton>
        <Typography id="modal-title" variant="h6" component="h2">
          Failure Report
        </Typography>
        {loading && <Typography id="modal-description" sx={{ mt: 2 }}>Loading report...</Typography>}
        {error && <Typography id="modal-description" sx={{ mt: 2, color: 'red' }}>{error}</Typography>}
        {htmlContent && (
          <iframe
            ref={iframeRef}
            srcDoc={htmlContent}
            title="Failure Report"
            style={{ width: '100%', height: '80vh', border: 'none' }}
          />
        )}
      </Box>
    </Modal>
  );
};

export default FailureReportModal;
